export function nameFormatter(value) {
    return allFormatterWithoutMinority(value.replace(/\d/g, ''));
}

export function allFormatter(value) {
    // 过滤输入特殊字符和空格
    return value.replace(/[\s+`!@#$%^&*()_\-+=<>?:"{}|,./;’\\\][·！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、']/g,"");
}

export function allFormatterWithoutMinority(value) {
    // 过滤输入特殊字符和空格 // 相比全量的少了·
    return value.replace(/[\s+`!@#$%^&*()_\-+=<>?:"{}|,./;’\\\][！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、']/g,"");
}

export function validateIdCard(idCard) {
    const reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
    if (idCard && reg.test(idCard)) {
        return true;
    } else {
        return false;
    }
}