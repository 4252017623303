<template>
  <div id="app">

    <div class="step1" v-show="step1Show">
      <div class="bank_sub_title">
        <span><font class=""></font>请完善以下资料</span>
      </div>

      <div class="txb2 mgb_0">
        <van-field
            v-model="info.certNum"
            required
            maxlength="18"
            label="身份证号码"
            :formatter="allFormatter"
            placeholder="请输入身份证号码"
            @input="checkIsOkToNext"
        />
      </div>

      <div class="txb2 mgb_0">
        <div class="bank_sub_title">
          <span>请点击上传身份证</span>
          <label>拍照时请确保身份证边框完整、图像清晰、光线均匀。</label>
        </div>
        <div class="bank_upload clear">
          <ul class="clear">
            <li>
              <van-uploader v-model="idcardList2" name="idcard2" accept="image/*"
                            :after-read="uploadIdCardToBank" :max-count="1"/>
              <span>请上传身份证人像面</span>
            </li>
            <li>
              <van-uploader v-model="idcardList1" name="idcard1" accept="image/*"
                            :after-read="uploadIdCardToBank" :max-count="1"/>
              <span>请上传身份证国徽面</span>
            </li>
          </ul>
          <div class="bank_upload_b clear">
            <img src="../../../src/assets/images/photo_upload_b.jpg" alt=""/>
          </div>
        </div>
      </div>

      <div class="txb2">
        <van-checkbox-group v-model="result">
          <van-cell-group>
            <van-cell
                v-for="(item, index) in list"
                :border="false"
                clickable
                :key="item"
                title="身份证有效期是否为长期"
                @click="toggle(index)"
            >
              <template #right-icon>
                <van-checkbox :name="item" ref="checkboxes"/>
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <van-field
            v-if=!isValidDateLong
            required
            v-model="info.certValidPeriod"
            is-link
            readonly
            label="有效期限"
            placeholder="请选择有效期限"
            @click="showCertValidPeriod = true"
        />
      </div>

      <div class="txb2">
        <van-field
            :border="false"
            label="性别"
            is-link
            required
            readonly
            v-model="info.sex.name"
            @click="showSex = true"
            placeholder="请选择性别"
        />
      </div>

      <div class="txb2">
        <van-field
            required
            v-model="fieldValue"
            is-link
            readonly
            label="居住地"
            placeholder="请选择居住地所在地区"
            @click="show = true"
        />
        <van-popup v-model="show" round position="bottom">
          <van-cascader
              v-model="cascaderValue"
              title="请选择居住地所在地区"
              :options="options"
              @close="show = false"
              @change="onChange"
              @finish="onFinish"
          />
        </van-popup>
      </div>

      <div class="txb2">
        <van-field
            v-model="info.address"
            required
            maxlength="50"
            label="详细地址"
            placeholder="请输入详细地址"
            @input="checkIsOkToNext"
        />
      </div>

      <div class="txb2">
        <van-field
            required
            v-model="career.value"
            is-link
            readonly
            label="职业"
            placeholder="请选择职业"
            @click="showCareer = true"
        />
      </div>

      <div class="bank_btn ">
        <van-button color="#e9331e" @click="upgradeAccount">提交</van-button>
      </div>
    </div>

    <van-popup v-model="showValidStart" position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
          v-model="validStart"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          title="选择年月日"
          @cancel="showValidStart = false"
          @confirm="validStartSelect"
      />
    </van-popup>

    <van-popup v-model="showCertValidPeriod" position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
          v-model="timeSelect"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          title="选择年月日"
          @cancel="showCertValidPeriod = false"
          @confirm="showCertValidPeriodSelect"
      />
    </van-popup>

    <van-popup v-model="showCareer" position="bottom" :style="{ height: '50%' }">
      <van-picker
          v-model="career"
          :columns="careerList"
          value-key="value"
          show-toolbar
          title="选择职业"
          @cancel="showCareer = false"
          @confirm="careerSelect"
      />
    </van-popup>

    <van-action-sheet
        v-model="showSex"
        :actions="sexList"
        @select="sexSelect"
    />

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>上传中...</van-loading>
    </div>

  </div>
</template>

<style scoped>
#app {
  background: #f8f8f8;
  height: 100vh;
  overflow: scroll;
  position: relative;
  z-index: 2000;
}


::v-deep .van-uploader__upload {
  margin: 0 !important;
  width: 40vw !important;
  height: 23vw !important;
  background: url(../../../src/assets/images/photo_upload.png) center center;
  background-size: 100% 100%;
}

::v-deep .bank_upload ul li:nth-child(2) .van-uploader__upload {
  margin: 0 !important;
  width: 40vw !important;
  height: 23vw !important;
  background: url(../../../src/assets/images/photo_upload2.png) center center;
  background-size: 100% 100%;
}

::v-deep .van-uploader__preview {
  margin: 0 !important;
}

::v-deep .van-uploader__preview-image {
  width: 40vw !important;
  height: 23vw !important;
  overflow: hidden;
}

::v-deep .van-uploader__upload-icon {
  display: none;
}

/* ::v-deep .van-field__label {
    width: 9em !important;
} */

::v-deep .van-cell__value {
  /* text-align: left !important; */
}

</style>

<script>
import Vue from 'vue'
import {post} from "@/utils/http";
import {getRealLength} from "@/utils/util";
import Notify from "vant/es/notify";
import {Dialog} from 'vant';
import moment from "moment";
import {allFormatter, allFormatterWithoutMinority, nameFormatter, validateIdCard} from "../../utils/formatter";

export default {
  data() {
    return {
      radio: '1',
      result: [],
      list: ["date"],
      isValidDateLong: false,
      showValidDate: true,

      //居住地地区选择
      show: false,
      fieldValue: '',
      cascaderValue: '',
      // 选项列表，children 代表子选项，支持多级嵌套
      options: null,
      index0: -1,
      index1: -1,
      index2: -1,

      step1Show: true,
      step2Show: false,
      step3Show: false,

      showKeyboard1: false,
      showKeyboard2: false,
      showKeyboard3: false,

      loading: false,
      info: {
        name: "",
        code: "",
        cardType: 1,// 1为身份证
        certNum: "",
        validStart: "",
        imageDataNo: "",
        sex: {
          name: "",
          value: "",
        },
        country: "CN",
        career: "",// 一级职业
        occupation: "",//岗位
        address: "",
        bindingCardNo: "",
        mobile: "",
        password: "",
        repassword: "",
        merchantNum: '',
        certValidPeriod: '', // 有效结束时间
        paymentPlatformId: "73d1c2341636435f8e364f9db552a277", // 广发写死
      },
      img1: '',
      img2: '',
      idcardList1: [],
      idcardList2: [],
      minDate: new Date(new Date().getFullYear() - 50, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 50, 0, 1),
      showNotice: false,
      showNotice2: false,
      showValidStart: false,
      showCertValidPeriod: false,
      validStart: new Date(),
      timeSelect: new Date(),
      career: {},// 一级职业
      showSex: false,
      sexList: [
        {
          name: "男",
          value: 1,
        },
        {
          name: "女",
          value: 2,
        },
      ],
      showCareer: false,
      careerList: [],
      isOkToNext: true,
    };
  },
  mounted() {


    this.getOccupation();
    this.getProvinceCity();
  },
  methods: {
    getOccupation() {
      post('/api/guangfa/v2/queryOccupation', {}).then(r => {
        this.careerList = r.data.oLIST2;

        if (this.info.career) {
          this.career = this.careerList.find(item => item.code === this.info.career)
        }
      }).catch(err => {
        console.log(err);
      })
    },
    uploadIdCardToBank(file, name) {
      if (!this.info.certNum) {
        Notify({type: "danger", message: '请输入身份证号码后再进行上传操作'});

        if (name.name == 'idcard1') {
          this.idcardList1 = [];
          this.img1 = '';
        } else if (name.name == 'idcard2') {
          this.idcardList2 = [];
          this.img2 = '';
        }

        return false;
      }

      let that = this
      let func = function () {
        //判断是否已选择两张图片
        if (that.img1 && that.img2) {
          let content1 = that.img1.replace(/^data:image\/\w+;base64,/, '');
          let content2 = that.img2.replace(/^data:image\/\w+;base64,/, '');

          that.loading = true;
          post('/api/guangfa/v2/uploadCustImageData', {
            certNum: that.info.certNum,
            obverse: content1,
            reverse: content2
          }).then(r => {
            that.info.imageDataNo = r.data.imageDataNo;
            that.info.merchantNum = r.data.merchantNum;
            that.checkIsOkToNext();
            that.loading = false;
          }).catch(err => {
            Notify({type: "danger", message: err.message});

            // if (name.name == 'idcard1') {
            that.idcardList1 = [];
            that.img1 = '';
            // } else if (name.name == 'idcard2') {
            that.idcardList2 = [];
            that.img2 = '';
            // }
            that.loading = false;
          })
        }
      }

      if (name.name == 'idcard1') {
        // console.log('原始文件大小', file.content.length - (file.content.length / 8) * 2)
        this.dealImage(file.content, 700, (base64) => {
          that.img1 = base64;

          // var strLen = that.img1.length;
          // var fileSize = strLen - (strLen / 8) * 2
          // console.log("文件大小:" + fileSize);
          // console.log('that.img1', that.img1)

          func();
        });
      } else if (name.name == 'idcard2') {
        // console.log('原始文件大小', file.content.length - (file.content.length / 8) * 2)
        this.dealImage(file.content, 700, (base64) => {
          that.img2 = base64;

          // var strLen = that.img2.length;
          // var fileSize = strLen - (strLen / 8) * 2
          // console.log("文件大小:" + fileSize);
          // console.log('that.img2', that.img2)

          func();
        });
      }
    },
    stringTrim(str) {
      if (str.trim) return str.trim()
      return str;
    },
    cancel() {
      this.$router.back(-1);
    },
    validStartSelect(row) {
      this.info.validStart = row.getFullYear().toString() + '-' + (row.getMonth() < 9 ? '0' : '') + (row.getMonth() + 1).toString() + '-' + (row.getDate() < 10 ? '0' : '') + row.getDate().toString();
      this.showValidStart = false;
    },
    showCertValidPeriodSelect(row) {
      this.timeSelect = row;
      this.info.certValidPeriod = row.getFullYear().toString() + '-' + (row.getMonth() < 9 ? '0' : '') + (row.getMonth() + 1).toString() + '-' + (row.getDate() < 10 ? '0' : '') + row.getDate().toString();
      this.showCertValidPeriod = false;
      this.checkIsOkToNext();
    },
    careerSelect(row) {
      this.career = row;
      this.showCareer = false;
      this.checkIsOkToNext();
    },
    careerMaps(val) {
      let t = "请选择";
      this.careerList.forEach((r) => {
        if (r.value === val) {
          t = r.name;
        }
      });

      return t;
    },
    sexSelect(row) {
      this.info.sex = row;
      this.showSex = false;
      this.checkIsOkToNext();
    },
    sexMaps(val) {
      let t = "请选择";
      this.sexList.forEach((r) => {
        if (r.value === val) {
          t = r.name;
        }
      });

      return t;
    },
    //压缩base64图片
    dealImage(base64, w, callback) {
      var newImage = new Image();
      var quality = 0.9;    //压缩系数0-1之间
      newImage.src = base64;
      newImage.setAttribute("crossOrigin", 'Anonymous');	//url为外域时需要
      var imgWidth, imgHeight;
      newImage.onload = function () {
        imgWidth = this.width;
        imgHeight = this.height;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        if (Math.max(imgWidth, imgHeight) > w) {
          if (imgWidth > imgHeight) {
            canvas.width = w;
            canvas.height = w * imgHeight / imgWidth;
          } else {
            canvas.height = w;
            canvas.width = w * imgWidth / imgHeight;
          }
        } else {
          canvas.width = imgWidth;
          canvas.height = imgHeight;
          quality = 0.6;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
        var base64 = canvas.toDataURL("image/jpeg", quality); //压缩语句
        // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
        // while (base64.length / 1024 > 150) {
        // 	quality -= 0.01;
        // 	base64 = canvas.toDataURL("image/jpeg", quality);
        // }
        // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
        // while (base64.length / 1024 < 50) {
        // 	quality += 0.001;
        // 	base64 = canvas.toDataURL("image/jpeg", quality);
        // }
        callback(base64);//必须通过回调函数返回，否则无法及时拿到该值
      }
    },
    getProvinceCity() {
      post('/api/guangfa/v2/queryProvinceCity', {}).then(r => {
        if (r.data.errorCode === '0000' && r.data.region.length > 0) {
          this.options = [];
          for (let i = 0; i < r.data.region.length; i++) {
            const region = r.data.region[i];
            this.options = this.options.concat({text: region.regionName, value: region.regionCode, children: []});
          }

        }

      }).catch(err => {
        console.log(err);
      })
    },
    onChange({value, selectedOptions, tabIndex}) {
      let regionLevel = "";

      if (tabIndex === 0) {
        regionLevel = "2"
        this.index0 = 0;
        this.index1 = 0;
        this.index2 = 0;
        for (let i = 0; i < this.options.length; i++) {
          if (value === this.options[i].value) {
            this.index0 = i;
            break;
          }
        }
      } else if (tabIndex === 1) {
        regionLevel = "3";
        this.index1 = 0;
        this.index2 = 0;
        for (let i = 0; i < this.options[this.index0].children.length; i++) {
          if (value === this.options[this.index0].children[i].value) {
            this.index1 = i;
            break;
          }
        }
      }

      if (!regionLevel) {
        return;
      }

      post('/api/guangfa/v2/queryProvinceCity', {
        regionLevel: regionLevel,
        superiorRegCode: value
      }).then(r => {
        if (r.data.errorCode === '0000' && r.data.region.length > 0) {
          let children = [];

          if (tabIndex === 0) {
            for (let i = 0; i < r.data.region.length; i++) {
              const region = r.data.region[i];
              children = children.concat({text: region.regionName, value: region.regionCode, children: []});
            }
            this.options[this.index0].children = children;
          } else if (tabIndex === 1) {
            for (let i = 0; i < r.data.region.length; i++) {
              const region = r.data.region[i];
              children = children.concat({text: region.regionName, value: region.regionCode});
            }

            this.options[this.index0].children[this.index1].children = children;
          }

        }

      }).catch(err => {
        console.log(err);
      })

    },
    onFinish({value, selectedOptions, tabIndex}) {
      console.log("onFinish == " + value);
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
      for (let i = 0; i < this.options[this.index0].children[this.index1].children.length; i++) {
        console.log("onFinish == " + this.options[this.index0].children[this.index1].children[i].value);
        if (value === this.options[this.index0].children[this.index1].children[i].value) {
          this.index2 = i;
          console.log("onFinish == " + this.index2);
          break;
        }
      }
    },
    checkIsOkToNext() {
      /*
      if (!this.info.certNum || !this.stringTrim(this.info.certNum)) {
        this.isOkToNext = false;
        return;
      }
      if (!this.info.imageDataNo) {
        this.isOkToNext = false;
        return;
      }
      if (!this.isValidDateLong && !this.info.certValidPeriod) {
        this.isOkToNext = false;
        return;
      }
      if (!this.info.sex || !this.info.sex.value || !this.stringTrim(this.info.sex.value)) {
        this.isOkToNext = false;
        return;
      }
      if (!this.index2 || this.index2 < 0) {
        this.isOkToNext = false;
        return;
      }
      if (!this.info.address || !this.stringTrim(this.info.address)) {
        this.isOkToNext = false;
        return;
      }
      if (!this.career || !this.career.code || !this.stringTrim(this.career.code)) {
        this.isOkToNext = false;
        return;
      }
       */

      this.isOkToNext = true;
    },
    upgradeAccount() {
      if (!this.info.certNum) {
        Notify({type: "danger", message: '请填写身份证号'});
        return;
      }
      if (!this.validateIdCard(this.info.certNum)) {
        Notify({type: "warning", message: '身份证号码格式错误'});
        return;
      }
      if (!this.info.imageDataNo) {
        Notify({type: "danger", message: '请上传身份证'});
        return;
      }
      if (!this.isValidDateLong && !this.info.certValidPeriod) {
        Notify({type: "danger", message: '请输入有效期限'});
        return;
      }
      if (this.index0 < 0 && this.index1 < 0 && this.index2 < 0) {
        Notify({type: "danger", message: '请输选择居住地'});
        return;
      }
      if (!this.info.address) {
        Notify({type: "danger", message: '请输入详细地址'});
        return;
      }
      if (getRealLength(this.info.address) < 24) {
        Notify({type: "danger", message: '详细地址输入长度必须大于24长度（中文算2长度）'});
        return;
      }
      if (!this.career.code) {
        Notify({type: "danger", message: '请选择职业'});
        return;
      }

      this.info.career = this.career.code;

      let certValidPeriod = '';
      if (this.isValidDateLong) {
        const now = new Date();
        const newDate = new Date((now.getFullYear() + 20), now.getMonth(), now.getDay());
        certValidPeriod = moment(newDate.getTime()).format('YYYYMMDD');

      } else {
        certValidPeriod = moment(this.timeSelect.getTime()).format('YYYYMMDD');
        console.log(certValidPeriod);
      }

      // 去除前后空格
      let that = this;
      Object.keys(that.info).forEach(function (key) {
        that.info[key] = that.stringTrim(that.info[key])
      });

      let province = this.options[this.index0].value;
      let city = this.options[this.index0].children[this.index1].text;
      let cnty = this.options[this.index0].children[this.index1].children[this.index2].text;

      this.loading = true;
      // 三类户升级二类户
      post('/api/guangfa/v2/upgradeaccount', {
        reqType: 2,
        imageDataNo: this.info.imageDataNo,
        profession: this.info.career,
        address: this.info.address,
        yxSatus: "02",
        isOCR: 0,
        validDays: certValidPeriod,
        province: province,
        city: city,
        cnty: cnty,
        gender: this.info.sex.value,
      }).then(res => {
        this.loading = false;
        this.$router.replace({path: '/AccountCreateFeedback', query: {type: 'ID_CARD_REVIEWING'}})
      }).catch(err => {
        this.loading = false;
        Dialog.alert({
          confirmButtonText: '确认',
          title: '温馨提示',
          message: err.message
        }).then(() => {
        });
        // this.$router.replace({path: '/AccountCreateFeedback', query: {type: 'FAIL', msg: error.message}})
      })
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
      this.isValidDateLong = !this.isValidDateLong;
      this.checkIsOkToNext();
    },
    nameFormatter(value) {
      return nameFormatter(value);
    },
    allFormatterWithoutMinority(value) {
      return allFormatterWithoutMinority(value);
    },
    allFormatter(value) {
      return allFormatter(value);
    },
    validateIdCard(idCard) {
      return validateIdCard(idCard);
    }
  }
}
;
</script>
